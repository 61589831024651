import React, { useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { Flex } from "theme-ui"

import Dismiss from "../assets/svg/dismiss.svg"
import useDisableBackgroundScroll from "./useDisableBackgroundScroll"

const Drawer = styled(Flex)`
  z-index: 999998;
  transition: all 400ms ease-in;
  visibility: visible;
  justify-content: flex-end;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .content {
    transition: all 400ms;
    padding-top: calc(${(props) => props.theme.navbar.height} + 10px);
    transform: translateX(0%);
    overflow: auto;
  }

  ${(props) =>
    props.asModal &&
    `
    justify-content: center;
    align-items: center;

    .dismiss-container {
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .content {
      padding-top: 0px;
      padding: 20px;
      margin: 0 16px;
      height: fit-content;
      width: fit-content;
    }

    ${props.theme.breakpointsLegacy.tablet}{
      .content {
        padding: 32px;
        margin: 0px;
      }
    }
    `}

  ${(props) =>
    !props.visible &&
    `
     visibility: hidden;
     background-color: transparent;

     .content {
        transform: translateX(100%);
     }

     ${
       props.asModal &&
       `
      .content {
        transform: translateY(100vh)
      }
      `
     }
  `}
`

const Content = styled.div`
  background-color: ${(props) => props.theme.colors.grayscale[100]};
  overflow: scroll;
  height: 100%;

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    width: 60%;
    padding: 0px 5%;
  }
`

const DismissContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  margin-right: 16px;
  margin-bottom: 90px;

  svg {
    cursor: pointer;
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    margin-right: 0px;
  }
`

const Backdrop = styled.div`
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  visibility: visible;
  opacity: 1;
  backdrop-filter: blur(15px);
  transition: visibility 0.4s, opacity 0.4s;

  ${(props) =>
    !props.visible &&
    `
    visibility: hidden;
    opacity: 0;
  `}
`

const useDrawerHook = ({
  initialComponent = null,
  config = {},
  onCloseClick = () => null,
  onBackdropClick = () => null,
}) => {
  const [content, setContent] = useState(initialComponent || null)
  const [visible, setVisible] = useState(false)
  const contentRef = useRef()
  const [, setScrollable] = useDisableBackgroundScroll()
  const { asModal, sx } = config

  const handleMouseClick = (e) => {
    if (contentRef.current) {
      if (!contentRef.current.contains(e.target)) {
        setVisible(false)
        onBackdropClick()
      }
    }
  }

  useEffect(() => {
    if (visible) {
      setScrollable(false)
    }
    if (!visible) {
      setScrollable(true)
    }
  }, [setScrollable, visible])

  const component = (
    <>
      <Backdrop visible={visible} />
      <Drawer
        visible={visible}
        onClick={(e) => handleMouseClick(e)}
        asModal={asModal}
        sx={sx}
      >
        <Content ref={contentRef} className="content" asModal={asModal}>
          <DismissContainer className="dismiss-container">
            <Dismiss
              onClick={() => {
                setVisible(false)
                onCloseClick()
              }}
            />
          </DismissContainer>
          {content}
        </Content>
      </Drawer>
    </>
  )
  return {
    drawerContent: component,
    setDrawerContent: setContent,
    drawerVisible: visible,
    setDrawerVisible: setVisible,
  }
}

export default useDrawerHook
