// External packages
import * as React from "react"
import { Box, BoxProps, Flex } from "theme-ui"

export const SizePickerGrid: React.FC<BoxProps> = ({
  children,
  sx,
  ...rest
}) => (
  <Box
    {...rest}
    sx={{
      ...sx,
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      "> *:nth-child(even)": {
        borderInlineEnd: "0",
        paddingInlineStart: 7,
      },
      "> *:nth-child(odd)": {
        paddingInlineEnd: 7,
      },
      "> *:nth-last-child(1), > *:nth-last-child(2):nth-child(odd)": {
        borderBlockEnd: "0",
      },
    }}
  >
    {children}
  </Box>
)

export const SizePickerGridItem: React.FC<BoxProps> = ({
  children,
  sx,
  ...rest
}) => (
  <Flex
    {...rest}
    as="button"
    sx={{
      ...sx,
      color: "inherit",
      display: "block",
      height: 15,
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: "transparent",
      cursor: "pointer",
      border: 0,
      borderBlockEnd: "1px solid",
      borderBlockEndColor: "grayscale.200",
      borderInlineEnd: "1px solid",
      borderInlineEndColor: "grayscale.200",
      padding: 0,
    }}
  >
    {children}
  </Flex>
)
