import { PricedProduct } from "@medusajs/medusa/dist/types/pricing"

export const getVariantsInventory = (product: PricedProduct) => {
  let inventory = {}

  product?.variants?.map(
    (v) =>
      (inventory[v.id] = v.allow_backorder
        ? Infinity
        : v.inventory_quantity || 0)
  )

  return inventory
}
